import React, {useState, useMemo, useRef, useEffect, useCallback} from 'react';
import { usePersistedDataStore } from '../store';
import MuiTable from "./MuiTable";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import { blue } from '@mui/material/colors';
import TableCustomToolbar from './TableCustomToolbar';
import { saveComps } from '../../common/saveComps'
import Map from './Map';
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import cloneDeep from 'lodash/cloneDeep';
import { PropertyInfoTooltip } from './MuiTable';
import axiosInstance from '../../axiosConfig';
import MiscAdjustmentModal from './MiscAdjustmentModal';
import CloseIcon from '@mui/icons-material/Close';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { debounce } from 'lodash';

const CustomCheckboxHeader = React.memo(({ indeterminate, onChange }) => { // if this could access the api ref 
  return (
    <div
    className={`items-center justify-center content-center m-auto flex h-full w-full ${indeterminate ? '' : ''}`}
    style={{
      width: 22, // Match the size of your checkbox
      height: 22, // Match the size of your checkbox
    }}
  >
      {indeterminate ?
      <Checkbox
        indeterminate={indeterminate}
        onChange={onChange}
        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 24 }} />} // Ensure consistent fontSize for unchecked state
        sx={{
          '& .MuiSvgIcon-root': {
            fontSize: 26, // Apply consistent sizing to all icons
          },
        }}
      />
      :
      <div className='w-[10px]'>#</div>
    }

    </div>
  );
}, (prevProps, nextProps) => {
  return prevProps.allRowsSelected === nextProps.allRowsSelected &&
          prevProps.indeterminate === nextProps.indeterminate;
});


// Function to extract the offer value or return "nc" for "stip no change" cases.
const extractOfferValue = (text) => {
  // Check for various "stip no change" phrases, case-insensitive
  if (/\b(?:stip\s*)?(?:n[\/\.\s]?c(?:hange)?|no\s*(?:change|chg|chg)|nochg)\b|\b(?:offer|offering)\s+(?:n[\/\.\s]?c(?:hange)?|no\s*(?:change|chg|chg)|nochg)\b/i.test(text)) {
    return "nc";
  }

  // Updated logic to match "offer" or "offering" followed by a number with optional decimal points
  const match = text.match(/(?:offering|offer)\s+(\d+(?:\.\d+)?[km]|\d+(?:,\d{3})*)|(\d+(?:\.\d+)?[km]|\d+(?:,\d{3})*)\s*(?:offering|offer)/i);

  if (match) {
    // Get the matched number part from the relevant capturing group
    let offerValue = match[1] || match[2]; // Groups for "offering x/offer x" and "x offering/x offer"

    if (offerValue) {
      if (offerValue.includes("k")) {
        // If it includes 'k', remove 'k' and multiply by 1000
        offerValue = parseFloat(offerValue.replace("k", "")) * 1000;
      } else if (offerValue.includes("m")) {
        // If it includes 'm', remove 'm' and multiply by 1,000,000
        offerValue = parseFloat(offerValue.replace("m", "")) * 1000000;
      } else {
        // Remove commas from the number and parse it as an integer
        offerValue = parseInt(offerValue.replace(/,/g, ""), 10);
      }
      return offerValue; // Return the parsed number
    }
  }
  
  return null; // Return null if no match
};

  // Debounce the toast error function to trigger after 1 second
  const useDebouncedToast = () => {
    const debouncedToastRef = useRef(
      debounce(() => {
        toast.error('Offer value is more than 25% less than the IFMV. Please review the offer value. If this is intended, manually type in the offer value.');
      }, 1000)
    );
  
    return debouncedToastRef.current;
  };

const CustomCheckboxCell = React.memo(({ checked, selectedRowNumber }) => {
  let number = selectedRowNumber // if selectedrownumber = 0 only way itll be selected.
  return (
    <div className='items-center justify-center flex h-full'>
      <Checkbox
        icon={<CheckBoxOutlineBlankIcon style={{ fontSize: 24 }} />}
        checkedIcon={
          <div style={{
            position: 'relative',
            width: 20,
            height: 20,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: blue[700],
            borderRadius: '10%',
          }}>
            <span style={{
              position: 'absolute',
              fontSize: 16,
              color: '#fff',
              fontWeight: 'bold',
            }}>
              {number}
            </span>
          </div>
        }
        checked={selectedRowNumber>0 ? checked:false}
      />
    </div>
  );
});

export const AdjustmentCell = ({
  params,
  selectedAdjustments,
  caseObject,
  handleAddAdj,
  savedComps,
  subject = false,
  handleDeleteAdj,
}) => {

  let value = params.value;
  // Format value as a 
  if (!isNaN(value) && value !== '') { 
    value = Number(value.toFixed(0)).toLocaleString() || '';
  }
  const parcelID = params.row.parcel_id;
  let indexInSavedComps = savedComps.indexOf(parcelID);

  const adjustments =
    Object.keys(selectedAdjustments).length > 0
      ? selectedAdjustments
      : caseObject.MiscAdjustments;

  if (indexInSavedComps === -1 && !subject) {
    return (
      <div className="flex font-bold justify-end items-center">
        {value !== undefined && value !== null && value !== '' ? (
          <span className="justify-end">{value}</span>
        ) : (
          'Error'
        )}
      </div>
    );
  }

  // console.log(params.row.PropertyInfo.Address)
  const adjustmentTooltips = [];
  // console.log(adjustments)
  for (const key in adjustments) {
    // WHY IS THE SUBJECT ADJ FLAG RELEVANT HERE?
    // It should really just be "if the adj is applied" or not.
    const adjustmentData = adjustments[key];
    const subjectAdjustmentFlag = adjustmentData.Values[0] || 0;
    const compAdjustmentFlag = adjustmentData.Values[indexInSavedComps + 1] || 0;
    const adjustmentValue = Number(adjustmentData.Adjustment) || 0;
    // Im not sure about this formula. this was what claudia gave me.
    const adjustment = (subjectAdjustmentFlag - compAdjustmentFlag) * adjustmentValue;

    if (adjustment !== 0) {
      adjustmentTooltips.push({
        key,
        name: adjustmentData.Name,
        type: adjustmentData.AdjustmentType,
        adjustmentValue: adjustmentData.Adjustment,
        adjustment,
        parcelId: parcelID,
      });
    }
  }
  // console.log(adjustmentTooltips)

  return (
    <div className="flex group justify-end h-full relative">
      <div className="hidden w-6 group-hover:flex relative items-center justify-center">
        <IconButton
          className="mr-0"
          onClick={() => handleAddAdj(parcelID)}
          sx={{ padding: '1px', fontSize: '0.5rem' }}
        >
          <AddIcon />
        </IconButton>
      </div>

      {adjustmentTooltips.length > 0 ? (
        <>
        <Tooltip
          title={
            <div
              style={{
                padding: '8px',
                maxWidth: '300px', // Set a max width if desired
                overflowY: 'auto', // Allow vertical scrolling if too long
                maxHeight: '400px', // Set a max height to prevent overflow
                whiteSpace: 'normal', // Allow text to wrap
              }}
            >
              {adjustmentTooltips.map((adjustmentData) => (
                <div key={adjustmentData.key} className="flex items-center justify-between">
                  {adjustmentData.type === 'percentage' ? (
                  <span>{`${adjustmentData.name}: ${(adjustmentData.adjustmentValue)}%`}</span>
                ) : (
                  <span>{`${adjustmentData.name}: $${Math.round(adjustmentData.adjustmentValue).toLocaleString()}`}</span>
                )}
                  <IconButton
                    onClick={(e) => {
                      handleDeleteAdj(adjustmentData);
                    }}
                    sx={{ color: 'red' }}
                    size="small"
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </div>
              ))}
            </div>
          }
          placement="top"
          arrow
        >
          <Typography className="flex items-center text-orange-500 cursor-pointer">
            <span className="mr-1">$</span>
            <span className="font-bold text-black text-sm">{value}</span>
          </Typography>
        </Tooltip>
      </>
      
      ) : (
        <div className="flex font-bold justify-end items-center">
          {value !== undefined && value !== null && value !== '' ? (
            <span className="justify-end">{value}</span>
          ) : (
            'Error'
          )}
        </div>
      )}
    </div>
  );
};

// VERY IMPORTANT NOTE - The utilization of MUI's api reference may not be stable indefinitely.
// We updated to the paid plan but haven't fully transitioned the code, I think we have the ability to do multiple row updates at once
// now instead of the iterative single update we're currently doing.
// There is a dumb flow that occurs here. You are rendering your casereview page twice on load w/ the useeffects/ 
const CaseReview = ({compObject, resetUpdateArrayCallback, compStreamObject, updatedArray, handleUpdateStateCallback, caseObject, optimizedObject, savedComps, globalCompRef}) => {  // update baths field to be "half baths", full baths, and total baths which is displayed by default.
  // This component is poorly optimized. this can be observed if you try to rapidly clickthrough the comps.
  // This issue is made worse by the home rerendering on view swap - which is dumb.
  const location = useLocation();
  const [selectedAdjustments, setSelectedAdjustments] = useState(caseObject?.MiscAdjustments||{});
  const getStyleOptions = usePersistedDataStore((state) => state.styleOptions);
  const getViewOptions = usePersistedDataStore((state) => state.viewOptions);
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj)
  const queryParams = new URLSearchParams(location.search);
  const comp = useMemo(() => ( parseInt(queryParams.get('comp')) - 1 || 0), [queryParams]);
  const taxYear = useMemo(() => {
    return parseInt(queryParams.get('TaxYear')) || 0; // Correctly fetch TaxYear and handle NaN
  }, [queryParams]);
  const village = queryParams.get('village') || 'All';
  const adjustmentRef = useRef({});
  const [rowUpdate, setRowUpdate] = useState(false);
  const memoizedRowUpdate = useMemo(() => rowUpdate, [rowUpdate]);
  const memoizedCompObject = useMemo(() => compObject, [compObject]);
  const [selectedComps, setSelectedComps] = useState(null);
  const memoizedSelectedComps = useMemo(() => selectedComps, [selectedComps]);
  const previousComp = useRef(comp);
  const [caseNotes, setCaseNotes] = useState(caseObject?.CaseNotes||'');
  const [caseNotesChanged, setCaseNotesChanged] = useState(false);
  const caseNotesRef = useRef(caseNotes);
  const [view, setView] = useState('regular'); // changed view to be a local state here.
  const debouncedToast = useDebouncedToast();

  // get this in the caseobject from load_muni_cases - THEN figure out instantiating it correctly
  // its gonna be caseobject.OfferValue
  const [offerAmount, setOfferAmount] = useState(caseObject?.OfferValue||null);

  // Toggle adjustment display modal
  const handleAddAdj = useCallback(() => {
    toggleDisplayModal()
  }, []);

  const viewCallback = useCallback((newValue) => {
    const newView = newValue === 0 ? 'regular' : 'optimized';
    setView(newView);
  }, []);

  // This is so stupid, you need to have these things defined at a higher level as props I think and pass them in
  // I think you cause excess component rerenders by having them here.
  // You could have a useeffect which calls the reset functions in the parent. rn this causes a double render of casereview (when there are notes, or offer values)
  // these essentially are functioning as bootleg useeffects. Was playing around with trying to fixx the rerenders.
  // these ones aren't at least optically problematic, 
  
  // At some point you need to address this, this should be handled byt eh parent component passing in props.
  // Reset selectedComps when comp changes
  if (previousComp.current !== comp) {
    if(Object.keys(selectedAdjustments).length!==0){
      console.log('there are adjustments, clearing user added adj')
      setSelectedAdjustments(caseObject?.MiscAdjustments||{})
    }
    if(selectedComps !== null){
      console.log('there are comps, clearing user added adj')
    setSelectedComps(null);
    }
    // if your current casenotes ref
    const fixedCaseNotes = caseObject.CaseNotes || '';
    if(fixedCaseNotes !== caseNotesRef.current){
      console.log('updating casenotes')
    setCaseNotes(fixedCaseNotes);
    caseNotesRef.current = fixedCaseNotes;
    }
    // This enables your save button when updating notes.
    if(caseNotesChanged){
      console.log('casenotes changed')
      setCaseNotesChanged(false);
    }
    if(caseObject.OfferValue){
      console.log('there is ano ffer')
      setOfferAmount(caseObject.OfferValue)
    }else{
      if(offerAmount !== null){
        console.log('offer amount is not null')
        setOfferAmount(null)
      }
    }
    previousComp.current = comp; // Update the ref to the new value
  }
  // extract yourr subject and comps
  const subject = useMemo(() => {
    // console.log('subjrern')
    return( Object.fromEntries(
    Object.entries(memoizedCompObject).map(([key, value]) => [key, Array.isArray(value) ? value[0] : value])
    ))
  }, [memoizedCompObject]);


  
  // Memoize the optimized components
  const optimizedComps = useMemo(() => {
    const workingOptimizedObject = cloneDeep(optimizedObject);
    const currentOptimizedObject = Object.fromEntries(
      Object.entries(workingOptimizedObject).map(([key, value]) => [key, Array.isArray(value) ? value.slice(1) : value])
    );

    const updatedOptimizedObject = {};

    // REmoved the whole optim reordering function bc idt its necessary anymore with your new pinning.

    return currentOptimizedObject

    // Ensure there are savedComps to reorder
    if (savedComps.length !== 0) {
      savedComps.forEach((comp, indexInSaved) => {
        const parcel_id = comp;
        
        // Find the index of the current parcel_id in the original optimizedObject
        const indexInOptimized = currentOptimizedObject.parcel_id.indexOf(parcel_id);
        
        if (indexInOptimized !== -1) {
          // Move values for every key from indexInOptimized to indexInSaved
          Object.keys(currentOptimizedObject).forEach((key) => {
            if (key === 'price_adjustments') {
              // Do nothing since this is deeply nested and throws errors
              return;
            }
            // Ensure updatedOptimizedObject has the key initialized as an array
            if (!updatedOptimizedObject[key]) {
              updatedOptimizedObject[key] = [];
            }

            // Splice out the value from indexInOptimized
            const [valueToMove] = currentOptimizedObject[key].splice(indexInOptimized, 1);

            // Insert the value at the new indexInSaved position
            updatedOptimizedObject[key].splice(indexInSaved, 0, valueToMove);
          });
        } 
      });

      // Append remaining components that were not part of savedComps (if any)
      Object.keys(currentOptimizedObject).forEach((key) => {
        if (key === 'price_adjustments') {
          // Do nothing since this is deeply nested and throws errors
          return;
        }
        // Ensure the remaining values are added to the end of the updated currentOptimizedObject
        currentOptimizedObject[key].forEach((value) => {
          if (value !== undefined) { // Skip undefined values that were moved earlier
            if (!updatedOptimizedObject[key]) {
              updatedOptimizedObject[key] = [];
            }
            updatedOptimizedObject[key].push(value); // Add remaining values to the end
          }
        });
      });

      console.log(updatedOptimizedObject);
      return updatedOptimizedObject; // Return the final reordered object
    }

    return {}; // Return empty if there are no saved comps
  }, [optimizedObject, savedComps]);

  // Memoize the regular components
  const regularComps = useMemo(() => {
    return Object.fromEntries(
      Object.entries(memoizedCompObject).map(([key, value]) => [key, Array.isArray(value) ? value.slice(1) : value])
    );
  }, [memoizedCompObject]);

// Now you can use optimizedComps and regularComps independently
  const comps = useMemo(() => {
    if (view === 'optimized') {
      return optimizedComps;
    }
    return regularComps;
  }, [view, optimizedComps, regularComps]);
  
  const tableProps = useMemo(() => ({ // custom table props for case review vverrsion of table.
    density: 'compact', // how closely spaced
    disableDensitySelector: true, // remove this option from the table header
    disableColumnResize: true, // can change this enable resizing.
    checkboxSelection: false,
    compTable: true, // in lower levvel MuiTable use this to set the backgrorund color.
    disableRowSelectionOnClick: true, // this will disable row selection on click
    disableAutosize: true,
    hideFooter: true, // remove the pagination / footer.
  }), []);
  // comps to fill in from callback. Default value should be to pass in ur regular saved.

  // idk if any point to memoize, this should work. confirm doesn't cause issues.
  const handleUpdateSelectedComps = useCallback((comps) => {
    console.log(comps)
    setSelectedComps(comps);
  }, []);

  const imputedValueCheck = useCallback((stringValue) => {
    const value = stringValue || ''; // Ensure value is at least an empty string
    if (typeof value === 'string' && value.includes('*')) {
        return (
        <div style={{ color: '#d32f2f' }} className='h-full w-full flex items-center justify-center'>
          {/* ; // Render value in red if it contains an asterisk */}
        <span className='text-red-500 h-full w-full hover:text-blue-500'>{value}</span>
        </div>
        )
    }
  }, []);


  const headers = useMemo(() => [
    // checkbox selection (action col type)
    {
      field: 'actions',
      type: 'actions',
      hide: true,
      width: 55,
      headerClassName: 'actions',
      renderHeader: (params) => {
        const indeterminate = params.colDef.indeterminate
        // const indeterminate = pinnedRowsIds.top.length > 0 && pinnedRowsIds.top.length < rows.length;
        // Conditionally render the checkbox in the header
        const handleChange = (event) => {
          handleSelectAllChange(params);
        };
        return (
          <CustomCheckboxHeader
            indeterminate={indeterminate}
            onChange={handleChange}
          />
        ); // Hide header checkbox if nothing is selected
      },
      // Updating this to be based on the internal grid state the way you had it before.
      renderCell: (params) => {
        // console.log(params.row.selectedRowNumber)
        const reOrderedArray = params.colDef.reOrderArray || [];
        const selectedRows = params.colDef.selectionArray || []; // this is CORRECT now. (we drive this from MUI table.)
        let isSelected = false;

        // console.log(isSelected)
        // console.log('reordered array')
        // console.log(reOrderedArray)

        // console.log('selected rows')
        // console.log(selectedRows)

        let selectedRowNumber = '';
        if(reOrderedArray.length>0 || reOrderedArray===null){
          isSelected = reOrderedArray.includes(params.row.parcel_id);
          selectedRowNumber = reOrderedArray.indexOf(params.row.parcel_id) + 1;
        }
        // its ogttat be from this
        else if(selectedRows.length > 0){ // if > 0 you have as a user picked things.
          isSelected = selectedRows.includes(params.row.parcel_id);
          selectedRowNumber = selectedRows.indexOf(params.row.parcel_id) + 1;
        }

        // else{
        //     selectedRowNumber = savedComps.indexOf(params.row.parcel_id) + 1
        // }
        
            return <div className='flex items-center'>
            <CustomCheckboxCell 
            checked={isSelected}
            selectedRowNumber={params.row.selectedRowNumber||selectedRowNumber}
            />
            <span className='items-center min-w-[16px] cursor-pointer'> {params.row['#']}</span>
            </div>
      }
    },

    // # index
    // {
    //   field: '#',
    //   headerName: '#',
    //   align: 'center',
    //   headerAlign: 'center',
    //   width: 50,
    //   // valueGetter: (params) => params.api.getRowIndex(params.id) + 1,
    // },

    { // Prop info - address, sd, parcel id
      align: "left",
      editable: false,
      field: "PropertyInfo",
      headerName: "Address",
      minWidth: 120,
      flex: 1, // Allows the column to grow and shrink as needed
      valueGetter: (params) => params.Address, // updated this so you can search and filter by address (use value getter for this function)
      renderCell: (params) => {
        // console.log(params)
        // const ImgLink = await GetCompPhoto(params.row.parcel_id); // Fetch the image link
      
        return (
          <PropertyInfoTooltip
            address={params.row.PropertyInfo.Address}
            parcelId={params.row.PropertyInfo.parcel_id}
            SDName={params.row.PropertyInfo.SDName}
            SDCode={params.row.PropertyInfo.SDCode}
            town={params.row.PropertyInfo.Town}
            zip={params.row.PropertyInfo.Zip}
            MLSNumber={params.row.MLSNumber}
            remarks={params.row.Remarks}
            // imgLink={ImgLink} // Pass the image link to the tooltip
          />
        );
      }
    },

    { // Town
      editable: true,
      field: "Town",
      headerName: "Town",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      hide: true,
      flex: 0.6, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // SDName
      editable: true,
      field: "SDName",
      headerName: "SD Name",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      flex: 0.6, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // Zip
      editable: true,
      field: "ZipCode",
      headerName: "Zip",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      hide: true,
      flex: 0.5, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // PID
      editable: false,
      field: "parcel_id",
      headerName: "PID",
      type: 'string',
      align: "left",
      // list comprehensive value options here
      hide: true,
      flex: 0.9, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // PCC
    editable: false,
    field: "PCC",
    headerName: "PCC",
    type: "string",
    flex: 0.2, // Adjust this value based on proportion from the old width
    },

    { // Style
    editable: true,
    field: "Style",
    headerName: "Style",
    type: 'singleSelect',
    align: "left",
    // list comprehensive value options here
    valueOptions: getStyleOptions,
    hide: false,
    flex: 0.8, // Adjusted based on the old width of 100
    // cellClassName: (params) => console.log(params)
    },

    { // View
      editable: true,
      field: "View",
      headerName: "View",
      type: 'singleSelect',
      align: "left",
      // list comprehensive value options here
      valueOptions: getViewOptions,
      hide: false,
      flex: 0.7, // Adjusted based on the old width of 100
      // cellClassName: (params) => console.log(params)
    },

    { // Yr
    editable: true,
    field: "YearBuilt",
    headerName: "Yr",
    type: "number",
    flex: 0.5, // Adjust this value based on proportion from the old width
    valueGetter: (value) => {
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value === '') return '';
      const parsedValue = Number(value);
      if (!isNaN(parsedValue)) {
      const [integerPart, decimalPart] = parsedValue.toString().split('.');
      if (decimalPart && decimalPart.length > 4) {
        return `${parsedValue.toFixed(0)}*`;
      }
      return integerPart;
      }
      return '';
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Sqft
    editable: true,
    field: "Sqft",
    headerName: "Sqft",
    type: "number",
    hide: false,
    flex: 0.6, // Adjusted based on the old width of 70
    valueGetter: (value) => {
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value === '') return '';
      const parsedValue = Number(value);
      if (!isNaN(parsedValue)) {
      const formattedValue = Math.round(parsedValue).toLocaleString()
      const [integerPart, decimalPart] = parsedValue.toString().split('.');
      if (decimalPart && decimalPart.length >= 2) {
        return `${formattedValue}*`;
      }
      return parsedValue;
      }
      return '';
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Acres
    editable: true,
    field: "Acres",
    headerName: "Acres",
    type: "number",
    minWidth: 60,
    flex: 0.6, // Adjusted based on the old width of 55
    valueGetter: (value) => {
      
      // if the value has an asterisk, just return the value.
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value === '') return '';
      const parsedValue = Number(value);
      if (!isNaN(parsedValue)) {
      const [integerPart, decimalPart] = parsedValue.toString().split('.');
      if (decimalPart && decimalPart.length > 4) {
        return `${parsedValue.toFixed(2)}*`;
      }
      return parsedValue.toFixed(2);
      }
      return '';
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    // { // Grade
    //   editable: true,
    //   field: "Grade",
    //   headerName: "Grade",
    //   type: "String",
    //   minWidth: 60,
    //   flex: 0.6, // Adjusted based on the old width of 55
    //   },

      // { // Condition
      //   editable: true,
      //   field: "Condition",
      //   headerName: "Condition",
      //   type: "String",
      //   minWidth: 60,
      //   flex: 0.6, // Adjusted based on the old width of 55
      //   },

    { // Full Baths
      editable: true,
      field: "fullBaths",
      headerName: "Full Baths",
      type: "number",
      flex: 0.6, // Adjusted based on the old width of 6
    },

    { // Half Baths
    editable: true,
    field: "halfBaths",
    headerName: "Half Baths",
    type: "number",
    flex: 0.6, // Adjusted based on the old width of 6
    },

    { // Bathrooms
    editable: false,
    field: "totalBaths",
    headerName: "Baths",
    type: "number",
    flex: 0.4, // Adjusted based on the old width of 40
    valueGetter: (value) => {
      // made it so BR return just blank asterisk if zero
      // if the value has an asterisk, just return the value.
      if (typeof value === 'string' && value.includes('*')) return value;
      if(value == 0) return '0*';
      const parsedValue = Number(value);
      return parsedValue.toFixed(0);
    },
    renderCell: (params) => imputedValueCheck(params.value),
    },

    { // Assessment
    editable: true,
    field: "PropertyAssessment",
    headerName: "Assessment",
    type: "number",
    flex: 1, // Adjusted based on the old width of 100
    valueGetter: (value) => Number(value).toLocaleString() || '',
    },

    { // IFMV
    editable: false,
    field: "IFMV",
    headerName: "IFMV",
    type: "number",
    flex: 0.7, // Adjusted based on the old minWidth of 90
    renderCell : (params) => {
      const value = params.value
      if (!isNaN(value)) { 
      return Number(value.toFixed(0)).toLocaleString() || '';
      }
      // return Number(params.value).toLocaleString() || '';
    }
    },

    { // Sale Price
    editable: true,
    field: "SalePrice",
    headerName: "Sale Price",
    type: "number",
    flex: 0.8, // Adjusted based on the old minWidth of 90
    // valueGetter: (value) => Number(value).toLocaleString() || '',
    renderCell: (params) => params.value ? Number(params.value).toLocaleString() : '',
    },

    { // Sale Date
    editable: true,
    field: "SaleDate",
    headerName: "Sale Date",
    type: "date",
    flex: 0.8, // Adjusted based on the old minWidth of 90
    valueGetter: (value) => new Date(value) || '',
    },

    { // MLS number
      editable: true,
      field: "MLSNumber",
      headerName: "MLS Number",
      type: "number",
      flex: 0.7, // Adjusted based on the old minWidth of 90
      // value gettter that displays blank string if the value is 'None'
      valueGetter: (value) => {
        if(value==='None'){
          return '';
        }
        return value || '';
      },
    },

    { // Adj Sale
    editable: false,
    field: "adj_price",
    headerName: "Adj Sale",
    type: "number",
    flex: 0.8, // Adjusted based on the old width of 90
      valueGetter: (value) => {
      // console.log(value)
      if (!isNaN(value) && value !== '') { 
      return Number(value.toFixed(0));
      }
      // here you define the value for subject if hasn't sold.
      return 'None';
    },
    renderCell: (params) => (
      <AdjustmentCell 
        params={params} 
        savedComps={savedComps}
        selectedAdjustments={selectedAdjustments}
        caseObject={caseObject}
        handleAddAdj={handleAddAdj}
        handleDeleteAdj={handleDeleteAdj}
      />
    )
    },
    { // Distance
    editable: false,
    field: "Distance",
    headerName: "Dist (Mi)",
    type: "number",
    flex: 0.6, // Adjusted based on the old width of 100
    valueGetter: (value) => {
      if (!isNaN(value) && value !== '') { 
      return Number(value.toFixed(2)) || 0;
      }
    },
    },
  ], [getStyleOptions, imputedValueCheck, selectedAdjustments, rowUpdate, savedComps, caseObject]);

  // use this to trigger renders within the table - if there exists a selected adj for a parcelID, then display a green $ sign
  // next to sale px. See if this top level rerender is performant enough for now.
  const [displayModal, setDisplayModal] = useState(false);
  const toggleDisplayModal = useCallback(() => {
    setDisplayModal((prev) => !prev);
  }, []);

  // Delete your misc adjustments.
  const handleDeleteAdj = useCallback(async (adjData) => {
    console.log(adjData);

    try {
        // Pass this object into the backend for delete adjustment route
        const deleteAdjResponse = await axiosInstance.post('/delete_misc_adj', { //
            PID: caseObject.PID,
            adjustmentData: adjData,
            MuniCode: getNegotiationObj.MuniCode,
            TaxYear: taxYear,
            villageFlag: village === 'All' ? 0 : 1, // Check village value instead of hardcoding zero
        });


        // The delete adjustment thing comes back with the value field still.
        // DOUBLE CHECK THIS THAT IT HAS THE CORRECT VALUES IN THE RESPONSE (removing the one you ust killed)
        console.log(deleteAdjResponse.data);

        // Check for successful response
        if (deleteAdjResponse.data && deleteAdjResponse.data[0] !== false) {
            toast.success(`Successfully deleted misc adjustment ${adjData.name}`);
            console.log('remaining adjustments:', deleteAdjResponse.data[1]);
            const remainingMiscAdjustments = deleteAdjResponse.data[1];
            setSelectedAdjustments(remainingMiscAdjustments);
            const updateNegotiationObj = {...getNegotiationObj}
            // It doesn't seem like its properly updating the state of misc adjustments 
            // Test if u add one in, and then you go to try and delete it.
            caseObject.MiscAdjustments = remainingMiscAdjustments;
            updateNegotiationObj.cases[comp].MiscAdjustments = remainingMiscAdjustments;
            setNegotiationObj(updateNegotiationObj)
            setRowUpdate(!rowUpdate)
        } else {
            // Handle the case where the response is false or not successful
            toast.error('Failed to delete miscellaneous adjustment. Please try again.');
        }
    } catch (error) {
        console.error('Error deleting adjustment:', error);
        toast.error('An error occurred while deleting the miscellaneous adjustment. Please try again.');
    }
  }, [taxYear, village, caseObject.PID, getNegotiationObj.MuniCode, rowUpdate]);

  // Add misc adjustment.
  const handleAddAdjustment = useCallback(async (adjustmentData) => {
    console.log(adjustmentData);

    const adjustmentDataObject = {
        Name: adjustmentData.adjustmentName,
        Adjustment: adjustmentData.adjustmentValue,
        AdjustmentType: adjustmentData.adjustmentType === '%' ? 'percentage' : 'numerical', // Change this to be "numerical or percentage based on the value"
        Values: adjustmentData.selectedProperties,
    };

    console.log('Adding adjustment');

    const userObject = JSON.parse(localStorage.getItem('userInfo'));
    console.log(caseObject);

    try {
        const miscAdjustmentResponse = await axiosInstance.post('/add_misc_adj', {
            PID: caseObject.PID,
            adjustmentData: adjustmentDataObject,
            MuniCode: getNegotiationObj.MuniCode,
            TaxYear: taxYear,
            villageFlag: village === 'All' ? 0 : 1, // Check village value instead of hardcoding zero
            userID: userObject?.userId || null, // Update this from JSON local storage
            repID: caseObject.repId || null, // Update this from the negotiationObj
        });

        console.log(miscAdjustmentResponse.data);

        // Check for successful response
        if (miscAdjustmentResponse.data && miscAdjustmentResponse.data !== false) {
            toast.success('Successfully added misc adjustment');
            setSelectedAdjustments(miscAdjustmentResponse.data);
            // Here need to update your negotiation object too:
            const updateNegotiationObj = {...getNegotiationObj}
            updateNegotiationObj.cases[comp].MiscAdjustments = miscAdjustmentResponse.data;
            setNegotiationObj(updateNegotiationObj)
            setRowUpdate(!rowUpdate);
        } else {
            // Handle the case where the response is false or not successful
            toast.error('Failed to add miscellaneous adjustment. Please try again.');
        }
    } catch (error) {
        console.error('Error adding adjustment:', error);
        toast.error('An error occurred while adding the miscellaneous adjustment. Please try again.');
    }
  }, [caseObject, taxYear, village, rowUpdate]);
  
  const handleChangeNotes = useCallback((value) => {
    if (!caseNotesChanged) {
      setCaseNotesChanged(true);
    }

    caseNotesRef.current = value;
    setCaseNotes(value);

    // Extract offer value from the notes
    const extractedOfferValue = extractOfferValue(value);
    console.log(extractedOfferValue);

    if (extractedOfferValue === 'nc') {
      console.log('its no change');
      setOfferAmount(subject.IFMV);
    } else if (extractedOfferValue !== null) {
      setOfferAmount(extractedOfferValue); // Set the extracted offer amount immediately

      // Check if the extracted offer value is more than a 25% reduction from the IFMV
      if (extractedOfferValue < subject.IFMV * 0.75) {
        // Trigger the debounced toast function
        debouncedToast();
      } else {
        // Cancel the debounced toast if the condition is no longer met
        debouncedToast.cancel();
      }
    } else {
      // Set offerAmount to null only if it is currently not null
      if (offerAmount !== null) {
        setOfferAmount(null); // Clear the offer amount if it's currently set
      }
      // Cancel any pending toast if there is no extracted value
      debouncedToast.cancel();
    }
  }, [caseNotesChanged, offerAmount, subject.IFMV, extractOfferValue, setOfferAmount, debouncedToast]);

  
  const compRef = useRef(comp);
  useEffect(() => {
      compRef.current = comp;
  }, [comp]);
  // Create a ref for the debounced function to avoid recreating it on every render
  const debouncedUpdateCaseNotes = useRef(debounce((value) => {
    const updateNegotiationObj = { ...getNegotiationObj }; // Clone the current negotiation object
    updateNegotiationObj.cases[compRef.current].CaseNotes = value; // Update the case notes
    setNegotiationObj(updateNegotiationObj); // Update the state with the new object
  }, 500)).current;

  // Use an effect to ensure the debounced function is set up correctly
  useEffect(() => {
      return () => {
          debouncedUpdateCaseNotes.cancel(); // Cleanup on unmount
      };
  }, []);

    // this function is only iterating over the regular comps. Is this a problem?
    const memoizedLatLongs = useMemo(() => { 
      let latLongs;
      const compsToDisplay = memoizedSelectedComps ? memoizedSelectedComps : savedComps;
    
      // If there are no saved comps, return the first 5 entries from `compObject`
      if (compsToDisplay.length === 0) {
        console.log('no comps saved, displaying top 5');
        latLongs = compObject.parcel_id.slice(0, 1).map((parcelId, index) => ({
          parcel_id: parcelId,
          latitude: compObject.Latitude[index] || null,
          longitude: compObject.Longitude[index] || null,
        }));
      } else {
        // If there are saved comps or selected comps, use those
        // Map over compsToDisplay to get the lat/long values
        latLongs = compsToDisplay.map(parcelId => {
          const index = compObject.parcel_id.indexOf(parcelId);
          if (index !== -1) {
            return {
              parcel_id: parcelId,
              latitude: compObject.Latitude[index],
              longitude: compObject.Longitude[index],
            };
          } else {
            return null; // Explicitly return null if no match is found
          }
        }).filter(item => item !== null); // Remove undefined or null entries
      }
    
      // Always append the first lat/long entry from compObject
      const firstParcelId = compObject.parcel_id[0];
      const firstIndex = compObject.parcel_id.indexOf(firstParcelId);
    
      const firstLatLong = {
        parcel_id: firstParcelId,
        latitude: firstIndex !== -1 ? compObject.Latitude[firstIndex] : null,
        longitude: firstIndex !== -1 ? compObject.Longitude[firstIndex] : null,
      };
    
      // Return a new array with the firstLatLong added at the beginning
      return [firstLatLong, ...latLongs];
      
    }, [savedComps, compObject, memoizedSelectedComps]);
    

    const regularAvg = useMemo(() => {
      const regularAvg = regularComps.adj_price.slice(0, 5).reduce((acc, price) => acc + price, 0) / 5;
      return regularAvg;
    }, [comp, regularComps]);

    // can break this function up to memoize the value individuall (specifically for saved comps this would be smart)
    const compAvg = useMemo(() => {
      // console.log('calculating px')
      // take the average of selected, top 5 or saved comps.
      if (Array.isArray(selectedComps)) {
        // lookup selected comps from within comps.parcel_id array and extract the adj prices for each, thjen get avg.
        // if its an empty array, take the default top 5.
        if(selectedComps.length === 0){
          const compPrices = comps.adj_price.slice(0, 5);
          const sum = compPrices.reduce((acc, price) => acc + price, 0);
          return sum / compPrices.length;
        }else{
        const selectedCompPrices = selectedComps.map((parcelId) => {
          const index = comps.parcel_id.indexOf(parcelId);
          if (index !== -1) {
            return comps.adj_price[index];
          }
          return 0;
        });
        const sum = selectedCompPrices.reduce((acc, price) => acc + price, 0);
        return sum / selectedCompPrices.length;
        }
      }
    
      // if saved comps, then take their average.
      if(savedComps.length > 0){
        // console.log('There are previously saved comps')
        const selectedCompPrices = savedComps.map((parcelId) => {
          const index = comps.parcel_id.indexOf(parcelId);
          if (index !== -1) {
            return comps.adj_price[index];
          }
          return 0;
        });
        const sum = selectedCompPrices.reduce((acc, price) => acc + price, 0);
        return sum / selectedCompPrices.length;
      }else{
        // console.log('you have no saved comps, displaying top5.')
        // display default top5.
        const compPrices = comps.adj_price.slice(0, 5);
        const sum = compPrices.reduce((acc, price) => acc + price, 0);
        return sum / compPrices.length;
      }

    }, [comps, selectedComps]);

    const handleSelectAllChange = useCallback((params) => { // this function doesn't really do anything it could be fully internal in headers.
      // update your internal datagrid state (this is a little hacky) to be cleared out.
      console.log('removing selected comps')
      console.log(params)
      const apiRef = params.colDef.apiRef.current
      console.log(apiRef)
      // here, have it go through the selectionArray and set selected to false, then clear rray
      // const selectedRowIds = params.colDef.selectionArray || [];

      // Update each row and insert in order based on updatedSelectedRowIds
      // selectedRowIds.forEach((rowId, index) => {
      //   const row = rows.find(r => r.parcel_id === rowId); // Find the row based on rowId
      //   if (row) {
      //     const updatedRow = {
      //       ...row,
      //       isSelected: false, // Set `isSelected` based on the new array
      //       rowIndexPosition: 0, // Use index + 1 to maintain the order
      //       selectionArray: [],
      //       selected: false,
      //     };
      //     console.log(updatedRow)
      //     apiRef.current.updateRows([updatedRow]); // Update row in the grid
      //   }
      // });
      // console.log(apiRef)
      params.colDef.indeterminate = false
      params.colDef.selectionArray = []
      const column = apiRef.getColumn('actions')
      setSelectedComps([])
      setRowUpdate(!memoizedRowUpdate) // force a state update at lower level to clear out its local state in addition to this interal grid state.
      apiRef.updateColumns([column]);

    },[memoizedRowUpdate, setRowUpdate]);

    // console.log(subject)

    const createSubjectRows = () => {
      // Construct and return a single object instead of an array
      return {
        "#": '',
        PropertyInfo: {
          Address: subject.Address,
          parcel_id: subject.parcel_id,
          SDName: subject.SDName,
          SDCode: subject.SDCode,
        },
        parcel_id: subject.parcel_id,
        Town: subject.Town,
        ZipCode: subject.ZipCode,
        // If you want you can add in settlement information here.
        Name: caseObject?.Name || '',
        ScarIndex: caseObject?.SCARIndexNumber || '',
        PropertyAssessment: subject.AssessmentVillage !=='' && village!=='All' ? subject.AssessmentVillage : subject.PropertyAssessment,
        PCC: subject.PCC,
        // View needs to be updated to add other fields w/ a dict.
        View: subject.Waterfront === 1 ? 'Waterfront' : subject.Waterview === 1 ? 'Waterview' : '',
        SDName: subject.SDName,
        SDCode: subject.SDCode,
        Style: subject.Style,
        YearBuilt: subject.YearBuilt,
        Sqft: subject.Sqft,
        Acres: subject.Acres,
        fullBaths: subject.Bath,  // Use correct key
        halfBaths: subject.HalfBath,  // Use correct key
        totalBaths: subject.Bath + (subject.HalfBath / 2),  // Use correct keys
        IFMV: village !== 'All' ? subject.IFMV_Village : subject.IFMV,
        SalePrice: subject.SalePrice,
        MLSNumber: subject.MLSNumber,
        SaleDate: subject.SaleDate,
        adj_price: subject.adj_price,
        'Time adj': subject['Time adj'] || '',  // Use optional chaining to handle missing keys
        Distance: subject.Distance,
        RepId: subject.RepID ?? '',
        Deck: subject.Deck,
        Porch: subject.Porch,
        Fireplaces: subject.Fireplaces,
        Kitchens: subject.Kitchens,
        FinishedBasement: subject?.FinishedBasement || '', // this is only going to exist IF the data exists.
        CentralAir: subject.CentralAir,
        Heating: subject.Heating,
        ContractDate: subject.ContractDate,
        Patio: subject.Patio,
        TotalRooms: subject.TotalRooms,
        Remarks: subject.Remarks,
      };
    };

    const createRows = () => {
      // Assuming all arrays within `comps` have the same length, we can use any of the arrays, e.g., comps.Address.length
      const length = comps.Address.length;
      return Array.from({ length }, (_, index) => ({
        id: index,
        '#': index + 1, // Index starting from 1 for user readability
        PropertyInfo: {
          Address: comps.Address[index],
          parcel_id: comps.parcel_id[index],
          SDName: comps.SDName[index],
          SDCode: comps.SDCode[index],
          Town: comps.Town[index],
          Zip: comps.ZipCode[index],
        },
        // Address: comps.Address[index],
        parcel_id: comps.parcel_id[index],
        Town: comps.Town[index],
        ZipCode: comps.ZipCode[index],
        PropertyAssessment: comps.PropertyAssessment[index],
        PCC: comps.PCC[index],
        View: comps.Waterfront[index] === 1 ? 'Waterfront' : comps.Waterview[index] === 1 ? 'Waterview' : '',
        SDName: comps.SDName[index],
        SDCode: comps.SDCode[index],
        Style: comps.Style[index],
        YearBuilt: comps.YearBuilt[index],
        Sqft: comps.Sqft[index],
        Acres: comps.Acres[index],
        fullBaths: comps.Bath[index],
        halfBaths: comps.HalfBath[index],
        totalBaths: comps.Bath[index] + (comps.HalfBath[index] / 2),
        IFMV: comps.IFMV[index],
        SalePrice: comps.SalePrice[index],
        SaleDate: comps.SaleDate[index],
        MLSNumber: comps.MLSNumber[index],
        adj_price: comps.adj_price[index],
        'Time adj': comps['Time adj']?.[index] || '', // Optional chaining to handle missing keys
        Distance: comps.Distance[index],
        selected: savedComps.includes(comps.parcel_id[index]),
        isSelected: savedComps.includes(comps.parcel_id[index]),
        // Set status by village if a village run
        status: village !== 'All' ? comps.adj_price[index] > subject.IFMV_Village ? 'negative' : 'positive' : comps.adj_price[index] > subject.IFMV ? 'negative' : 'positive',
        Deck: comps.Deck[index],
        Porch: comps.Porch[index],
        Heating: comps.Heating[index],
        Fireplaces: comps.Fireplaces[index],
        Kitchens: comps?.Kitchens?.[index],
        Patio: comps.Patio[index],
        FinishedBasement: comps?.FinishedBasement?.[index] || 0, // Use optional chaining to handle missing keys
        CentralAir: comps.CentralAir[index],
        ContractDate: comps.ContractDate[index],
        TotalRooms: comps.TotalRooms[index],
        Remarks: comps.Remarks[index],
      }));
    };

    // is this the right dependency, or is it comp?
    const subjectRow = useMemo(() => {
      // console.log('rerender subject row')
      return(
      createSubjectRows()
    )
    }, [comps]);
    

    // You changed this to use the getNegotiation Obj, but it may be able to use the taxYear now that you fixed court selection bug.
    const handleSaveComps = useCallback(async (selectedRowIds) => {
      console.log(selectedRowIds)
      console.log('saving comps')
      console.log(taxYear)
      // If the user just updated the notes, then it saves with already existing comps.
      if(selectedRowIds.length === 0){
        selectedRowIds = savedComps
      }
      // you will also need to pass up any miscellaneous adjustments you create here.
      // save button doesn't disappear on reload? Might be a dev only issue.
      const userObject = JSON.parse(localStorage.getItem('userInfo'))
      const isArrayOfObjects = selectedRowIds.every(item => typeof item === 'object' && item !== null);
      
      console.log(taxYear) // this was coming back as undefined?? why? I swapped to negotiationObj taxyear instead
      
      // If it's an array of objects, process it as before; otherwise, use it directly
      let compsObject = isArrayOfObjects
      ? selectedRowIds.reduce((acc, value, index) => {
          acc[index + 1] = value.parcel_id;
          return acc;
        }, {})
      : selectedRowIds.reduce((acc, value, index) => {
          acc[index + 1] = value;
          return acc;
        }, {});
  
      // Ensure compsObject keys are sequential
      compsObject = Object.values(compsObject).reduce((acc, value, index) => {
        acc[index + 1] = value; // Re-index the values to make them sequential
        return acc;
      }, {});

      // find the subjectRow.parcel_id in getNegotiationObj.cases array of objects
      const subjectRowIndex = getNegotiationObj.cases.findIndex(caseObj => caseObj.PID === subject.parcel_id);
      // console.log('the subject index is:', subjectRowIndex)
      // console.log(compsObject)

      // If a match is found, subjectRowIndex will be >= 0; if no match, subjectRowIndex will be -1
      // this flow will break the user out if they are a non-client.
      if (subjectRowIndex !== -1) {
        console.log('Found matching case at index:', subjectRowIndex);
        if (!getNegotiationObj.cases[subjectRowIndex].Client) {
          console.log('saving nonclient')
          // 
          const saveObject = {
            NonClientFlag: 1,
            Cases: [
              {
                Subject: subjectRow.parcel_id,
                Comps: compsObject,
                CaseNotes: caseNotesRef.current,
              },
            ],
            VillageFlag: village!=='All' ? 1 : 0,
            TaxYear: getNegotiationObj.TaxYear,
          }


          console.log('params going into fetch',saveObject)

          try{
            const saveNonClientResponse = await axiosInstance.post('/save_non_client_comps', saveObject);
            console.log(saveNonClientResponse)
            toast.success('Comps saved successfully.')
          }
          catch(err){
            console.log(err)
            toast.error('There was an error saving the comps.')
          }
          const updatedNegotiationObj = {...getNegotiationObj}; // set this equal t
          updatedNegotiationObj.cases[comp].Comps = compsObject
          const today = new Date();
          updatedNegotiationObj.cases[comp].RunDate = today.toUTCString();
          console.log(updatedNegotiationObj)
          setNegotiationObj(updatedNegotiationObj)
          setRowUpdate(!rowUpdate)
          return
          // getNegotiationObj.cases[subjectRowIndex].Comps = compsObject;
        }
      } 

      const saveObject = {
        ManualReviewFlag: 1, 
        ManualOverride: 1, // this may only be relevant in the saveall version of function?
        Cases: [ // pass in as an object so the function works to be modular to save all too.
          {
          Subject: subjectRow.parcel_id,
          Comps: compsObject,
          CompAdjustments: {},
          OfferValue: offerAmount !== null && offerAmount !== 'nc' ? offerAmount : null,
          // include comps adjustments in comp Object? what is structure?
          CaseNotes: caseNotesRef.current,
          RepId: subjectRow.RepId !== "" ? parseInt(subjectRow.RepId, 10) : null,
          },
        ],
        UserId: userObject?.userId || null,
        VillageFlag: village!=='All' ? 1 : 0,
        TaxYear: getNegotiationObj.TaxYear,
      }

      console.log('your saveobject')
      console.log(saveObject)
      // return // REMOVE THIS BEFORE DEPLOYING

      const newComps = await saveComps(saveObject)
      // console.log(newComps) // make sure u can properly return whatever value. (can just be t/f for erro rhandling)
      // apiRef.current.updateColumns([column]);
      const updatedNegotiationObj = {...getNegotiationObj}; // set this equal t
      updatedNegotiationObj.cases[comp].Comps = compsObject
      updatedNegotiationObj.cases[comp].CaseNotes = caseNotesRef.current
      const today = new Date();
      updatedNegotiationObj.cases[comp].RunDate = today.toUTCString();
      console.log(updatedNegotiationObj)
      setNegotiationObj(updatedNegotiationObj)
      setRowUpdate(!rowUpdate)
      if(caseNotesChanged){
        setCaseNotesChanged(false);
      }
      // setRowUpdate(!memoizedRowUpdate) // force a state update at lower level to clear out its local state in addition to this interal grid state.
    }, [subjectRow, rowUpdate, taxYear,caseNotesChanged, savedComps, offerAmount]);

    // memoize rows
    const rows = useMemo(() => createRows(), [comps, rowUpdate]);

    // console.log(subjectRow)
    // console.log(rows)

//   LOOK INTO NEW MAPPING LIBRARY HERE - see if you ccan also impose a side streetview partition in the
    return (
      <div className='h-full'>

        {displayModal && 
        // remove this from here, it shoul dbe its own component.
        <MiscAdjustmentModal
        toggleDisplayModal={toggleDisplayModal}
        parcelId={subjectRow.parcel_id}
        selectedComps={selectedComps || savedComps}
        handleAddAdjustment={handleAddAdjustment}
        // if selectedcomps then this, otherwise saved.
        />
        
        }


            {/* here, based on if map is rendered, render the table in diff sizes. (just do classname update) */}
            <div className='h-full flex flex-col'>
              {/* <div className='flex-grow'>
              </div> */}
        <Map
        // Redo this, passing inteh subject Lat / long, and comp lat / long (can just pass int he object for now?)
          latLongs={memoizedLatLongs}
        />
        {/* render the map here, passing in the subject, and the comps (some # of comps) */}
        {/* here, will setting casenotes cause a whole re-render fuckfest? */}
        <div className='h-1/2'>
          <TableCustomToolbar 
          compAvg={compAvg}
          caseNotes={caseNotes}
          changeCaseNotes={handleChangeNotes}
          view={view}
          offerAmount={offerAmount}
          handleUpdateStateCallback={handleUpdateStateCallback}
          // Set rundate to today if it doesn't exist
          runDate={caseObject?.RunDate || new Date().toLocaleDateString('en-US')}
          regularAvg={regularAvg}
          compsSelected={selectedComps}
          savedComps={savedComps}
          viewCallback={viewCallback}
          globalCompRef={globalCompRef}
          // do yoiu need to memoize these selected comps? itll lrerender every time even if not bool flip.;
          />

          <MuiTable 
            subject={subjectRow}
            compStreamObject={compStreamObject}
            handleUpdateStateCallback={handleUpdateStateCallback}
            // Pass in a flag that "casenotes have been changed"
            handleSaveComps={handleSaveComps}
            originalRows={rows} // These may need to be memoized
            resetUpdateArrayCallback={resetUpdateArrayCallback}
            updatedArray={updatedArray}
            view={view}
            savedCompPids={savedComps}
            rowUpdate={memoizedRowUpdate}
            headers={headers}
            toggleDisplayModal={toggleDisplayModal}
            tableProps={tableProps}
            adjustmentRef={adjustmentRef}
            updateSelectedComps={handleUpdateSelectedComps}
            caseNotesChanged={caseNotesChanged}
          />
      </div>
    </div>
      </div>
    );
};
export default CaseReview