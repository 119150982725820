import React, {useMemo, useCallback, useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {usePersistedDataStore, useCompStore} from '../store';
import AdjustmentsPopover from './AdjustmentsPopover';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import CompNavigation from './CompNavigation';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash/debounce';
import InputAdornment from '@mui/material/InputAdornment';
import {NumericFormat} from "react-number-format";

function a11yProps(index) {
  // this some MUI shit. straight from chat. for the active / transitioned blue underline I believe.
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // If you have an input you want to automatically be formatted, use this
  export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={ref} // Pass the ref properly to the input element
        thousandSeparator
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isnumericstring='true' // Correct camelCase for React prop
      />
    );
  });

const TableCustomToolbar = ({ globalCompRef, handleUpdateStateCallback, offerAmount, view, viewCallback, runDate, regularAvg, savedComps, compsSelected, compAvg, caseNotes, changeCaseNotes }) => {
  // this input changes from controlled to uncontrolled. Haven't really looked into it RE: casenotes. At some point do this.
    const [value, setValue] = useState(0); // using local state, and re-rendering the entir etable when changing.
    // load in evalDate and getnegotiationObj
    const getAdjustments = useCompStore((state)=> state.adjustments)
    const getEvalDate = usePersistedDataStore((state) => state.evalDate);
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const setNegotiationObj = usePersistedDataStore((state)=> state.setNegotiationObj)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = useMemo(() => parseInt(queryParams.get('comp')) - 1 || 0, [queryParams]);
    
    // Have the initial value load in from your negotiation Object (if it exists)
    const [inputValue, setInputValue] = useState(offerAmount || '');
    // you should jsut be using offer amount and a callback to update it,

    // Update state to be the input param
    useEffect(() => {
      setInputValue(offerAmount || ''); // Update inputValue with offerAmount
    }, [offerAmount, comp]);

    // Debounced function to call when input changes
    const debouncedOnValueChange = useCallback(
      debounce((value) => {
        // console.log('its ivnoking')
        // onValueChange(value);
        const caseObject = getNegotiationObj.cases[comp];
        // if the caseObject doesn't have a key OfferValue, create it.
        if (!caseObject.hasOwnProperty('OfferValue')) {
          caseObject.OfferValue = {};
        }
        // set the value of the OfferValue key to the value of the input
        const offerValue = parseInt(value) || null
        caseObject.OfferValue = offerValue

        // Update your negotiation Object with this new value
        const updateNegotiationObj = {...getNegotiationObj};
        updateNegotiationObj.cases[comp] = caseObject;
        setNegotiationObj(updateNegotiationObj);
      }, 300), // Adjust debounce delay as needed
      [comp]
    );
  
    // implement regex to ensure numeric inputs only
    const handleChange = (event) => {
      const value = event.target.value;
      
      // Ensure only numeric inputs using regex
      if (/^\d*\.?\d*$/.test(value)) {
      setInputValue(value);
      
      // Call the debounced function immediately to avoid lag
      debouncedOnValueChange(value);
      }
    };
    
    // regularAvg
    // calculate pctAdj using ifmv - compAvg if view is not regular, if regular view use (ifmv-regularAvg)/ifmv
    const pctAdj = useMemo(() => {
      const IFMVTown = getNegotiationObj.cases[comp].IFMVTown;
      if (view === 'regular') {
        return (regularAvg - IFMVTown) / IFMVTown; // Flipped formula
      } else {
        return (compAvg - IFMVTown) / IFMVTown; // Flipped formula
      }
    }, [regularAvg, compAvg, view, comp]);

    // update this to be using the input value instead of the offer amount.
    const offerPctAdj = useMemo(() => {
      const IFMVTown = getNegotiationObj.cases[comp].IFMVTown;
      return (inputValue - IFMVTown) / IFMVTown
    }, [inputValue, comp]);
  
    // Determine the styling based on pctAdj value
    const pctAdjClass = pctAdj < 0 ? 'text-green-600' : 'text-red-500';

    // offer pct adj - handle color styling for exact matches (ie: the IFMV has some decimals so it may be -0.0000001 if equal.)
    const offerPctAdjClass = offerPctAdj < -0.0001 ? 'text-green-600' : 'text-red-500';

    // console.log(getNegotiationObj.cases[comp].IFMVTown)

    const getRar = usePersistedDataStore((state) => state.rar);
    const getEq = usePersistedDataStore((state) => state.eq);
    const formatEvalDate = (dateString) => {
      if (!dateString) return ''; // Handle case where date is null or undefined
      const [year, month, day] = dateString.split('-'); // Split by '-'
      return `${month}/${day}/${year}`; // Rearrange to 'mm/dd/yyyy'
    };

    // minor inefficency on rerenders of eval date.
    // console.log('TableCustomToolbar rendered');
    const formattedEvalDate = formatEvalDate(getEvalDate);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleViewChange = (e, newValue) => {
    setValue(newValue);
    viewCallback(newValue);
    }

    return (
        <div className=''>
            {/* castenotes / chat input */}
            {/* chat piece to be built. */}
            <div className='flex flex-grow'>
              <TextField
                hiddenLabel
                className='flex-grow py-0'
                InputProps={{
                    // disableUnderline: true,  // Optional: if you want to remove the underline
                    classes: {
                      input: 'py-2',  // Adjust padding (top-bottom padding) here
                    },
                }}
                id="filled-hidden-label-normal"
                // placeholder="Type casenotes, or ask me a question..."
                placeholder="Type any casenotes here"
                autoComplete='off'
                value={caseNotes}
                onChange={(e) => changeCaseNotes(e.target.value)}
                variant="filled"
              />
            </div>
      
      <div className='flex content-center items-center justify-between'>
      {/* Tabs navigation */}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className='flex self-end'>
          <Tabs
            value={value}
            onChange={handleViewChange}
            aria-label="basic tabs example"
            sx={{
              minHeight: '30px',  // Adjusting Tabs container height
            }}
            TabIndicatorProps={{
              sx: {
                height: '2px',  // Adjust the height of the bottom indicator
              },
            }}
          >
            <Tab
              // label="Regular"
              label="Analysis"
              {...a11yProps(0)}
              sx={{
                minHeight: '30px',  // Adjusting individual Tab height
                padding: '4px 12px',  // Adjusting vertical and horizontal padding
                fontSize: '0.875rem',  // Optional: Adjust text size to fit
              }}
            />
            <Tab
              label="Court"
              {...a11yProps(1)}
              sx={{
                minHeight: '30px',  // Adjusting individual Tab height
                padding: '4px 12px',  // Adjusting vertical and horizontal padding
                fontSize: '0.875rem',  // Optional: Adjust text size to fit
              }}
            />
          </Tabs>

        {/* The eval date and adj div */}
        <div className='text-sm ml-2'>
          {/* could move this to its own component if you start doing more with the adj price */}
          {/* Eval date popover on top of the adjustments */}
          <div className='flex justify-start items-center text-xs'>
            <span>Eval Date: {formattedEvalDate}</span>
            <InfoOutlinedIcon 
              className='cursor-pointer ml-1' 
              fontSize='inherit'
              onClick={(e) => setAnchorEl(e.currentTarget)}
            />
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <div className='flex flex-col'>
                <span>RAR: {getRar}</span>
                <span>EQ: {getEq}</span>
                </div>
              </Box>
            </Popover>
          </div>
        <AdjustmentsPopover adjustments={getAdjustments} />
        </div>

        {/* Client name and SCAR Index # */}
        <div className="text-sm ml-2 flex flex-col justify-center w-[135px]">
          {(getNegotiationObj.cases[comp].Name || getNegotiationObj.cases[comp].SCARIndexNumber) ? (
            <>
              {/* Client name with ellipsis overflow and tooltip */}
              {getNegotiationObj.cases[comp].Name ? (
                <Tooltip title={getNegotiationObj.cases[comp].Name} disableHoverListener={getNegotiationObj.cases[comp].Name.length <= 12}>
                  <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">
                    <span className="font-bold mr-1 pl-2">Client:</span>
                    {getNegotiationObj.cases[comp].Name}
                  </div>
                </Tooltip>
              ) : (
                <div className="text-xs opacity-50 pl-2">Client: N/A</div>  // {/* Placeholder if no client name */}
              )}

              {/* SCAR ID with placeholder if missing */}
              {getNegotiationObj.cases[comp].SCARIndexNumber ? (
                <Tooltip title={getNegotiationObj.cases[comp].SCARIndexNumber} disableHoverListener={getNegotiationObj.cases[comp].SCARIndexNumber.length <= 11}>
                <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">
                  <span className="font-bold mr-1 pl-2">SCAR ID:</span>
                  {getNegotiationObj.cases[comp].SCARIndexNumber}
                </div>
                </Tooltip>
              ) : (
                <div className="text-xs opacity-50 bg-yellow-500 rounded-lg pl-2">SCAR ID: N/A</div>  // {/* Placeholder if no SCAR ID */}
              )}
            </>
          ) : (
            <>
              {/* Placeholders if neither Name nor SCAR ID exists */}
              <div className="text-xs opacity-50 pl-2">Client: N/A</div>
              <div className="text-xs opacity-50 bg-yellow-500 rounded-lg pl-2">SCAR ID: N/A</div>
            </>
          )}
        </div>
        </Box>
        

        {/* /* Construct a toolbar component that displays here. Need to style like a MUI header. */}
        <div className='flex items-center justify-center gap-2'>
          <div className='flex flex-col items-center justify-center'>
                      {/* In theory if you save comps this should be overwritten */}
            {runDate ?
              <span className='text-xs'>
              Last Run: {new Date(new Date(runDate).getTime() + 46400000).toLocaleDateString('en-US', { 
                year: '2-digit', 
                month: '2-digit', 
                day: '2-digit' 
              })}
            </span>
            :
            null}

          <div className='flex items-center justify-center gap-2 text-sm mb-2'>
          {view === 'regular' ?
          <span className='flex items-center font-bold'>
          Regular Avg: ${Math.round(regularAvg).toLocaleString()} 
          <span className={`text-xs ml-1 flex items-center ${pctAdjClass}`}>
            ({Math.round(pctAdj * 100)}%)
          </span>
        </span>
          :
          <>
          <span className='flex items-center font-bold'>
          {
            // autistic way to do this, but it works.
            Array.isArray(compsSelected) ? compsSelected.length > 0 ? 'Selected Avg: ' : 
            'Top 5 Avg: '
            : 
            savedComps.length>0 ? 'Selected Avg: ' :
            'Top 5 Avg: '
            } 
            ${Math.round(compAvg).toLocaleString()}
            <span className={`text-xs flex ml-1 items-center ${pctAdjClass}`}>
            ({Math.round(pctAdj * 100)}%)</span>
          </span>
          </>
          }
          </div>
          </div>
            
            {/* Input for offer value - should abstract and use this for other numeric inputs at some point. */}
            <div className='flex items-center'>
              <TextField
                value={inputValue}
                onChange={handleChange}
                variant="outlined"
                placeholder="Offer Value"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputComponent: NumberFormatCustom,
                }}
                autoComplete='off'
                sx={{
                  width: 140, // Adjust as needed
                  "& .MuiInputBase-root": {
                    padding: "2px 14px", // Adjust vertical padding and horizontal padding
                    height: "32px", // Set a specific height if you want to control it
                  },
                }}
              />

              {/* Offer percentage display */}
              <span className={`text-xs ml-1 flex items-center ${offerPctAdjClass}`} style={{ width: '45px', minWidth: '45px' }}>
                {offerAmount ? `(${Math.round(offerPctAdj * 100)}%)` : ''}
              </span>

            </div>
        </div>

        <div className='w-[365px] justify-end text-right'>
          <CompNavigation
          globalCompRef={globalCompRef} 
          handleUpdateStateCallback={handleUpdateStateCallback}
          />
          {/* Comp navigation */}
        </div>

        </div>
      </div>
    );
};


export default TableCustomToolbar;