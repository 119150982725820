import React, { useState, useEffect, useCallback, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { useLocation } from 'react-router-dom';
import { useCompStore, usePersistedDataStore } from '../store.js';
import DownloadButton from './DownloadButton.js';
import { showModalAndWaitForSelection } from '../../common/ShowModalAndWaitForSelection.js'; // you should be using this instead of ur manual jury rigged version in fetchcomps.
import axiosInstance from '../../axiosConfig.js';
import SummaryCaseReviewTable from './SummaryCaseReviewTable.js';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { toast } from 'react-toastify';

function ReviewSettlements({isAdmin=false, handleUpdateStateCallback, negotiationCases, filterType, setFilterType}) {
  const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
  const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
  const getCompSheet = usePersistedDataStore((state) => state.compSheet)
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const comp = parseInt(queryParams.get('comp')) - 1 || 0 ; // Subtract 1
  const taxYear = queryParams.get('TaxYear');
  const courtDate = queryParams.get('CourtDate') || 'All';
  const MuniCode = queryParams.get('MuniCode') || 'All';

  const getfilterIndexes = usePersistedDataStore((state) => state.filterIndexes);
  const setFilterIndexes = usePersistedDataStore((state) => state.setFilterIndexes);

  // const [countState, setCountState] = useState(0);
  // const [totalScarCases, setTotalScarCases] = useState(0)
  // const [avgReduction, setAvgReduction] = useState(0);
  // const [totalNC, setTotalNC] = useState(0);
  // const [taxSavings, setTaxSavings] = useState(0)
    let runningSetlement = 0; // local var to tally your settlement value.
    let runningIFMV = 0; // var to tally your IFMV's (as cases are settled to calc the % reduct)
    let runningNC = 0;
  const fileName = `${MuniCode}_${taxYear}${courtDate === 'All' ? '' : `_${courtDate}`}`;
  const [selectionModel, setSelectionModel] = React.useState([]);


    // For the download
    const keys = ['PID', 'Address', 'PropertyTown', 'PropertyZip', 'Name', 'SCARIndexNumber','AssessmentTown', 'IFMVTown', 'RunDate', 'SalePrice', 'SaleDate', 'RecentSale', 'EstimatedTaxes', 'SubjectMarketValue', 'OfferValue', 'RAR', 'RequestedAssessment', 'PctReduction', 'CaseNotes', 'RepID', 'settled'];

    // LETS UPDATE this to be memoized not 
    useEffect(() => {
      updateNegotiation(); // Consolidated function call
    }, []);

    // This is sort of dumb, but currently it is what puts your needed vals into the negotiation obj for summary table.
    // This is also very obviously inefficient and is re-running stuff that you dond't need to
    const updateNegotiation = useCallback(async () => {
      try{
      let runningSettlement = 0;
      let runningIFMV = 0;
      let runningNC = 0;
      console.log('updating negotiation');

      const casesToMap = getNegotiationObj.cases;
      
      // Check if all cases already have RequestedAssessment keys
      const allCasesHaveRequestedAssessment = casesToMap.every(
        (caseItem) => caseItem && caseItem.RequestedAssessment !== undefined
      );
    
      if (allCasesHaveRequestedAssessment) {
        console.log('All cases already have RequestedAssessment keys. No update needed.');
        return; // Exit early if no update is necessary
      }
    
      // Load sales data
      const uids = casesToMap.map((item) => item.PID);
      const salesResponse = await axiosInstance.post('/get_sales_data', {
        TaxYear: taxYear, // use the param taxyear instead
        MuniCode: MuniCode,
        uids: uids,
      });
    
      const salesData = salesResponse.data;
      const updatedCases = casesToMap.map((caseItem) => {
        if (caseItem && caseItem.RequestedAssessment !== undefined) {
          // Skip recalculating if RequestedAssessment already exists
          return caseItem;
        }
    
        const saleInfo = salesData.find((sale) => sale.pid === caseItem.PID);
        if (saleInfo) {
          return {
            ...caseItem,
            SaleDate: saleInfo.saleDate,
            SalePrice: saleInfo.salePrice,
            RecentSale: saleInfo.recentSale,
          };
        }
        return caseItem;
      });
    
      // Initialize negotiation object with updated cases
      const finalNegotiationObj = { ...getNegotiationObj, cases: updatedCases };
    
      // Update the cases within the final negotiation object
      finalNegotiationObj.cases = finalNegotiationObj.cases.map((item, index) => {
        // Skip updating cases that already have RequestedAssessment
        if (item && item.RequestedAssessment === undefined && item.type !== null) {
          const compSheetItem = getCompSheet[index];
          if (compSheetItem && compSheetItem.PropertyAssessment) {
            let requestedAssessment = item.SubjectMarketValue * compSheetItem.RAR[0];
            if (item.OfferValue) {
              const offeredAssessment = item.OfferValue * compSheetItem.RAR[0];
              requestedAssessment = offeredAssessment;
            }
    
            if (
              compSheetItem.IFMV[0] > 450000 &&
              requestedAssessment < 0.75 * compSheetItem.PropertyAssessment[0]
            ) {
              item.SubjectMarketValue = 0.75 * compSheetItem.IFMV[0];
              requestedAssessment = 0.75 * compSheetItem.PropertyAssessment[0];
            }
    
            let percentReduction = (
              (compSheetItem.PropertyAssessment[0] - requestedAssessment) /
              compSheetItem.PropertyAssessment[0]
            );
    
            return {
              ...item,
              Address: compSheetItem.Address[0],
              PropertyAssessment: compSheetItem.PropertyAssessment[0],
              RAR: compSheetItem.RAR[0],
              RequestedAssessment: parseInt(requestedAssessment),
              PctReduction: percentReduction,
              PropertyTown: compSheetItem.Town[0],
              PropertyZip: compSheetItem.ZipCode[0]
            };
          }
        }
        return item;
      });
    
      // Set the updated negotiation object only once
      setNegotiationObj(finalNegotiationObj);

    }
    catch(e){
      console.log(e);
      toast.error('Error updating negotiation object');
    }
    
    }, [getNegotiationObj.cases, MuniCode, axiosInstance, getCompSheet]);
     

    const handleCellClick = useCallback((selection) => {
      console.log("Row ID:", selection);
      setSelectionModel(selection);
    }, [selectionModel]);

  return (
    // On this page, if there's no settlements (or even if there are) - have an option to "save review" - this will save the comp order,
    // And save any min/max offers (and settlements)
    <div className=''>
      <div className='h-32 bg-white mt-4 flex flex-col justify-center'> 
        <div className='text-2xl font-bold text-center'>Settlement Review</div>
        <div className='text-center'>Placeholder for district summary stats.</div>
      </div>
      <div className='flex justify-between mt-2 mb-0.5'>
      {/* Button to download JSON data based on selection */}
        {/* <Button onClick={()=>setReviewPage(false)}
          startIcon={<KeyboardBackspaceIcon/>}
            >Back to Comps
        </Button> */}

        {/* May not want to use this at all and move to just an inbuilt flexi filter w/ datagrid? */}
        <FormControl>
          <InputLabel sx={{ zIndex: '1 !important', background: 'transparent' }}>Case Filter</InputLabel>
          <Select
              sx={{
                minWidth: 120,
                '& .MuiSelect-select': {
                    py: 1, // Apply padding in the y-direction (top and bottom)
                },
                '& .MuiInputBase-input': {
                    py: 1, // Apply padding for the input element
                },
            }}
              value={filterType}
              onChange={(e) => setFilterType(e.target.value)}
              label="Filter Type"
              className='z-50'
          >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="unreviewed">Unreviewed</MenuItem>
              <MenuItem value="scar">SCAR</MenuItem>
              <MenuItem value="open">Unsettled</MenuItem>
              <MenuItem value="settled">Settled</MenuItem>
          </Select>
      </FormControl>

        <DownloadButton
          caseData={negotiationCases}
          fileName={fileName}
          selectedRows={selectionModel}
          keys={keys}
        />

        {/* Relaly the filter should be rendered here. */}
      </div>
    
    {/* Your primary case review table rendering. */}
    <SummaryCaseReviewTable handleCellClick={handleCellClick} selectionModel={selectionModel} 
    negotiationCases={negotiationCases}
    handleUpdateStateCallback={handleUpdateStateCallback} updateNegotiation={updateNegotiation}/>
    
    </div>
  );
} export default ReviewSettlements
