import React, { useMemo, useEffect, useRef, useState } from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useDemoData } from '@mui/x-data-grid-generator';
import { usePersistedDataStore, useLocalDataStore } from '../store';
import { useNavigate } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material'; // Import MUI components

const CaseNavigation = ({ address, compNumber }) => {
    const setReviewPage = useLocalDataStore((state) => state.setReviewPage);
    const navigate = useNavigate();
    const handleClick = () => {
        const params = new URLSearchParams(window.location.search);
        params.set('comp', compNumber);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        setReviewPage(false);
        navigate(newUrl);
    };

    return (
        <div>
            <span onClick={handleClick} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>
                {address}
            </span>
        </div>
    );
};

function SummaryCaseReviewTable({ handleCellClick, selectionModel, handleUpdateStateCallback, updateNegotiation, negotiationCases }) {
    // Use this obj to update the value of neg obj back to original
    const setNegotiationObj = usePersistedDataStore((state) => state.setNegotiationObj);
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj);
    const intervalRef = useRef(null);

    useEffect(() => {
      console.log('running');
    }, []);

    useEffect(() => {
        const startInterval = () => {
            intervalRef.current = setInterval(() => {
                updateNegotiation();
                if (handleUpdateStateCallback()) {
                  // here, re-invoke the filtering once its done?
                    clearInterval(intervalRef.current);
                }
            }, 2000);
        };

        startInterval();
        return () => clearInterval(intervalRef.current);
    }, [handleUpdateStateCallback, updateNegotiation]);

    const handleSelectionChange = (newSelection) => {
        handleCellClick(newSelection);
    };

    const checkRepID = (cases) => {
      return cases.every(c => !c.RepID || c.RepID.trim() === '');
    };

    const headers = useMemo(() => {
      const defaultHeaders = [
        { field: "Comp", headerName: "#", type: 'number', align: "center", headerAlign: 'center', maxWidth: 50  },
  
        { field: "Address", headerName: "Address", align: "left", editable: false, minWidth: 120, flex: 1, renderCell: (params) => {
          return params.row.Address ? (
            <CaseNavigation
              address={params.row.Address}
              compNumber={params.row.Comp}
            />
          ) : (
            <div style={{ color: '#888', fontStyle: 'italic' }}>Loading...</div> // Or use a spinner component
          );
        }},
        { field: "PID", headerName: "PID", type: 'string', align: "left", flex: 0.6 },
        { field: "SCARID", headerName: "SCAR Id", type: 'string', align: "left", flex: 0.6 },
        { field: "AssessmentTown", headerName: "Assessment", type: 'string', align: "left", valueGetter: (value) => !isNaN(value) ? Number(value.toFixed(0)).toLocaleString() : '', flex: 0.6 },
        { field: "IFMVTown", headerName: "Eq Mkt Val", type: 'number', align: "left", valueGetter: (value) => {return !isNaN(value) ? Number(value) : null;},valueFormatter: (value) => {return !isNaN(value) ? `$${value.toLocaleString()}` : '';},flex: 0.6},
        // Column for Workup Value (Base Market Value)
        {
          field: "MarketValue",
          headerName: "Workup Value",
          type: 'number',
          align: "left",
          headerAlign: "left",
          valueFormatter: (value) => {
            return !isNaN(value) ? `$${Math.round(value).toLocaleString()}` : 'Loading...'; // Display 'Loading...' if no value
          },
          renderCell: (params) => {
            // Display "Loading..." if Address is undefined or falsy
            if (params.row.Address === undefined || params.row.Address === '' || params.row.Address===null) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>Loading...</div>;
            }
  
            // Check if mkt val is null, and show "None"
            if (params.row.MarketValue === null) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>None</div>;
            }
        
            // If Address is valid, display it
             // If the value is a number, display it formatted
             return !isNaN(params.row.MarketValue) 
             ? `$${Math.round(params.row.MarketValue).toLocaleString()}`
             : 'Loading...';
         },
         flex: 0.6
       },
    
        // Column for Settlement Value (SCAR Determination Value / RAR)
        {
          field: "SCARDeterminationValue",
          headerName: "Settlement Value",
          type: 'number',
          align: "left",
          renderCell: (params) => {
            // Display loading if SCARDeterminationValue or RAR is missing
            if (params.row.Address === undefined || params.row.Address === '' || params.row.Address===null) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>Loading...</div>;
            }
            
            if (!params.row.SCARDeterminationValue || !params.row.RAR) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>None</div>;
            }
    
            const settlementValue = params.row.Settled 
              ? params.row.SCARDeterminationValue / params.row.RAR 
              : null;
    
            return !isNaN(settlementValue) 
              ? `$${Math.round(settlementValue).toLocaleString()}`
              : 'Loading...';
          },
          flex: 0.6
        },
    
        // Column for Pct Reduction
        {
          field: "pctReduction",
          headerName: "Pct Reduction",
          type: 'number',
          align: "left",
          headerAlign: "left",
          renderCell: (params) => {
  
            if (params.row.Address === undefined || params.row.Address === '' || params.row.Address===null) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>Loading...</div>;
            }
  
            if (!params.row.IFMVTown || !params.row.SCARDeterminationValue || !params.row.RAR) {
              return <div style={{ color: '#888', fontStyle: 'italic' }}>None</div>; // Show loading message
            }
    
            let pctReduction = params.row.Settled 
              ? ((params.row.IFMVTown - params.row.SCARDeterminationValue / params.row.RAR) / params.row.IFMVTown) * 100 
              : 0;
    
            // Snap negative pctReduction to zero if it's between -0.24 and 0
            if (pctReduction < 0 && pctReduction > -0.24) {
              pctReduction = 0;
            }
    
            // Set color based on pctReduction
            const color = pctReduction > 0.25 ? 'green' : pctReduction < 0.24 ? 'red' : 'black';
    
            return (
              <span style={{ color }}>
                {pctReduction.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%
              </span>
            );
          },
          flex: 0.6
        },
    
        { field: "OfferValue", headerName: "Offer", type: 'number', align: "left", headerAlign: "left", flex: 0.6 },
        { field: "status", headerName: "Status", type: 'string', align: "left", renderCell: (params) => {
          const isSettled = params.row.Settled;
          return <span style={{ fontWeight: isSettled ? 'normal' : 'bold', color: isSettled ? 'green' : 'inherit' }}>{isSettled ? "Settled" : "Open"}</span>;
        }, flex: 0.6 },
      ];
    
      // Conditionally add "Rep ID" column if any case has a non-empty RepID
      if (!checkRepID(negotiationCases)) {
        defaultHeaders.push({
          field: "RepID",
          headerName: "Rep ID",
          type: 'string',
          align: "left",
          flex: 0.6,
        });
      }
    
      return defaultHeaders;
    }, [negotiationCases]);

    const createRows = (cases, fullNegotiationCases) => {
      return cases.map((currentCase) => {
        console.log()
        // Find the index of the current case in the full fullNegotiationCases.cases array
        const fullIndex = fullNegotiationCases.findIndex(c => c.PID === currentCase.PID);
        return {
          id: fullIndex,
          Comp: fullIndex + 1, // The comp number is based on the full array index
          Address: currentCase.Address,
          PID: currentCase.PID,
          Town: currentCase.Town,
          ZipCode: currentCase.ZipCode,
          AssessmentTown: currentCase.AssessmentTown,
          OfferValue: currentCase.OfferValue || null,
          IFMVTown: currentCase.IFMVTown,
          SCARID: currentCase.SCARIndexNumber,
          MarketValue: currentCase.SubjectMarketValue,
          pctReduction: currentCase.PctReduction * 100,
          SalePrice: currentCase.SalePrice || null,
          SaleDate: currentCase.SaleDate || null,
          RAR: currentCase.RAR,
          RepID: currentCase.RepID,
          SCARDeterminationAction: currentCase.SCARDeterminationAction,
          SCARDeterminationValue: currentCase.SCARDeterminationValue,
          Settled: ['S', 'SD', 'ST', 'W', 'NM', 'AH'].includes(currentCase.SCARDeterminationAction),
        };
      });
    };
    

    const rows = useMemo(() => createRows(negotiationCases,getNegotiationObj.cases), [negotiationCases, getNegotiationObj.cases]);
  console.log(negotiationCases)

    const tableProps = useMemo(() => ({
        density: 'compact',
        disableRowSelectionOnClick: true,
    }), []);

    return (
        <div style={{ height: 500, width: '100%' }}>
            <DataGridPro
                {...tableProps}
                rows={rows}
                columns={headers}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 50, 100]}
                onRowSelectionModelChange={handleSelectionChange}
                rowSelectionModel={selectionModel}
                componentsProps={{
                    columnMenu: {
                        disableColumnMenu: true,
                    },
                }}
            />
        </div>
    );
}

export default React.memo(SummaryCaseReviewTable);
